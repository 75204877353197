1<template>
  <div class="outer">
    <div class="hl-header">
      <div class="hl-search">
        <div class="hl-title">父菜单管理</div>
        <el-button
          v-if="chuangjian"
          type="primary"
          @click="addIdType(0, null)"
          class="addIdType"
          >+ 创建父菜单</el-button
        >
      </div>
    </div>
    <div class="hl-content">
      <!-- <div class="mokuai">父菜单</div> -->
      <el-table
        class="hl-table"
        :data="tableData"
        height="90%"
        :header-cell-style="{ background: '#F4F5F9', color: '#000000' }"
      >
        <el-table-column label="序号">
          <template slot-scope="scope">
            <span>{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column label="模块编号">
          <template slot-scope="scope">
            <span>{{ scope.row.moduleNo }}</span>
          </template>
        </el-table-column>
        <el-table-column label="父级模块号">
          <template slot-scope="scope">
            <span>{{ scope.row.parentModule }}</span>
          </template>
        </el-table-column>
        <el-table-column label="模块名称">
          <template slot-scope="scope">
            <span>{{ scope.row.moduleName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="排序">
          <template slot-scope="scope">
            <span>{{ scope.row.dispOrder }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div class="cz">
              <div class="qidong">
                <el-button
                  v-if="xiugai"
                  type="text"
                  @click="edit(scope.$index, scope.row)"
                  >编辑</el-button
                >
                <el-button
                  style="color: red"
                  type="text"
                  @click="del(scope.row)"
                  >删除</el-button
                >
              </div>
              <el-button
                v-if="mingxi"
                class="cz-button"
                size="mini"
                @click="caidanSon(scope.row)"
                >子模块</el-button
              >
              <!-- <el-button
                v-if="mingxi"
                class="cz-button"
                size="mini"
                @click="manage(scope.row)"
                >权限列表</el-button
              > -->
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 删除 -->
    <el-dialog title="提示" :visible.sync="deleteVisible" width="30%">
      <el-input
        placeholder="请输入删除指示"
        v-model="todelete"
        style="width: 100%"
        maxlength="10"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteSure">确 定</el-button>
      </span>
    </el-dialog>
    <addModify ref="addModify" />
  </div>
</template>
<script>
import { getRequest, postRequest, deleteRequest } from "@/api/api.js";
import addModify from "@/views/caidan/addModify.vue";
import store from "../../store";
export default {
  data() {
    return {
      tableData: [],
      list: [],
      input: "",
      totolNums: -1,
      name: "",
      code: "",
      condition: {
        offset: 1,
        num: 10,
      },
      chuangjian: false,
      xiugai: false,
      shanchu: false,
      mingxi: false,
      deleteVisible: false,
      row: {},
      todelete: "",
    };
  },
  components: {
    addModify,
  },
  created() {
    console.log(JSON.parse(window.localStorage.getItem("power")));

    // return;
    if (!this.power.checkedpower("0101004")) {
      //在PMS管理系统里面-获取权限
      this.$router.push("power");
    }
    // 创建
    this.chuangjian = this.power.checkedpower("0101001");
    // // 修改
    this.xiugai = this.power.checkedpower("0101002");
    // // 删除
    this.shanchu = this.power.checkedpower("0101003");
    // // 明细
    this.mingxi = this.power.checkedpower("0101008");
    this.getFounderList();
  },
  methods: {
    // 获取权模块列表
    getFounderList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      let parms = {
        parentNo: "00",
      };
      getRequest("selectAllModule", parms).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          console.log(res);
          this.tableData = res.data;
          // this.totolNums = res.data.totalNum;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    // 新增
    addIdType() {
      let state = "创建";
      this.$refs.addModify.show(state);
    },
    // 修改
    edit(index, row) {
      let state = "编辑";
      this.$refs.addModify.show(state, row, index);
    },
    // 删除
    del(row) {
      this.deleteVisible = true;
      this.todelete = "";
      this.row = row;
    },
    deleteSure() {
      if (this.todelete != "yes") {
        this.$message({
          duration: 5000,
          message: "请输入指示！",
          center: true,
        });
        return;
      }
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      let parms = {
        moduleNo: this.row.moduleNo,
      };
      deleteRequest("deleteSysModule", parms).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.$message({
            duration: 5000,
            message: "删除成功!",
            type: "success",
          });
          this.deleteVisible = false;
          this.getFounderList();
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    // 子菜单
    caidanSon(row) {
      store.infor = row;
      this.$router.push({
        name: "caidanSon",
        query: row,
      });
    },
    changePage(val) {
      this.condition.page = val;
      this.getFounderList();
    },
    // 每页的数据数
    onPaginationSize(val) {
      this.condition.num = val;
      this.getFounderList();
    },
    // 页数
    onPaginationIndex(val) {
      this.condition.offset = val;
      this.getFounderList();
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.outer {
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.button {
  width: 60px;
  height: 30px;
  /* background:rgb(163, 197, 248) ; */
  background-color: cornflowerblue;
  color: white;
  border: none;
}
.button2 {
  background-color: white;
  border: 1px solid #999;
  color: #666;
}
.hl-header {
  width: 100%;
  height: 60px;
  display: flex;
}
.hl-search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  position: relative;
}
.hl-title {
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.85);
}
.addIdType {
  width: 136px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
}
.hl-search .span {
  width: 40px;
  height: 30px;
  font-size: 14px;
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 14px;
  border: none;
}
.hl-content {
  flex: 1;
  background-color: #fff;
  padding: 21px 32px;
  position: relative;
}
.mokuai {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  opacity: 0.85;
  margin-bottom: 21px;
}
.hl-table {
  /* background: red; */
  overflow: auto;
}
.cz-button {
  width: 74px;
  height: 28px;
  /* background: #6ec543; */
  background: #387dff;
  opacity: 1;
  border-radius: 4px;
  color: #fff;
}
.qidong {
  width: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 24px;
}
.line {
  display: inline-block;
  height: 14px;
  border: 1px solid #e8ecef;
}
.cz {
  display: flex;
}
.block {
  position: absolute;
  bottom: 2px;
  right: 32px;
}
.qiyong {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #387dff;
  border-radius: 50%;
  margin-right: 6px;
}
.jinyong {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #f5222d;
  border-radius: 50%;
  margin-right: 6px;
}
.zt > div {
  display: flex;
  align-items: center;
}
.dialog-footer > .el-button {
  width: 60px;
  height: 30px;
}
</style>